import React from "react";
import ServiceHeader from "./ServiceHeader";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";

// import Chekout from "./Chekout";/
import Reg2 from "../Reg2";
import { all } from "axios";

import { Link, Navigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Loader from "./Loader";

const Pcbassembly = () => {
  const [days, setDays] = useState([]);
  const [show, setshow] = useState(false);

  const [layer, setlayer] = useState([]);
  const [LayerData, setLayerData] = useState([]);
  const [ThicknessData, setThicknessData] = useState([]);
  // console.log(layer,"this is real la yer ")
  const [components, setComponents] = useState([]);
  const [loading, setLoading] = useState(true);

  let navigate = useNavigate();

  const [GerberData, setGerberData] = useState(null);
  
  const [Materials, setMaterials] = useState(null);

  const MaterialsChange = (event) => {
    const file = event.target.files[0];
    

    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    if(fileExtension == 'xls' || fileExtension == 'xlsx'){
      setMaterials(file);
    }else{
      setMaterials(null);
      alert("Please Select Xls or Xlsx File Only.")
    }

    // setMaterials(file);
  };

  const GerberDataChange = (event) => {
    const file = event.target.files[0];

    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    if(fileExtension == 'zip' || fileExtension == 'rar'){
      setGerberData(file);
    }else{
      alert("Please Select zip or rar File Only.")
      setGerberData(null);
    }

    
  };


  const handleDeleteFile2 = () => {
    // Remove the selected file
    setGerberData(null);
  };
  const handleDeleteFile = () => {
    // Remove the selected file
    setMaterials(null);
   
  };


  const handleMaterialsFileDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setMaterials(droppedFile);
    // alert(selectedFile.name);
  };
  const handleGerberFileDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setGerberData(droppedFile);
    // alert(selectedFile.name);
  };


  useEffect(() => {
    GetNote();
    const apiUrl = APP_URL2 + "no_of_boards_to_be_assembled_get_data"; // Replace this with your API endpoint URL
    console.log(apiUrl);
    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setLayerData(data?.layer_data);
        setThicknessData(data?.Thickness);

        setlayer(data?.data);
        // console.log(data.data[0]);
        GetPricing();

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  function GetPricing() {
    

    var BGA_components_Pads = parseInt($('#BGA_components_Pads').val());
    var Throughhole_components_pads = parseInt($('#Throughhole_components_pads').val());
    var total_BGA_pads = parseInt($('#total_BGA_pads').val());
    var assembled_boards = parseInt($('#assembled_boards').val());
    var multifly_factor = 0;
    // $("#assembled_boards option:selected").data("target")
    var assembled_boards_no = parseInt($('#assembled_boards_no').val());
    if(assembled_boards_no <= 10){
      multifly_factor = 0.5;
    }else if(assembled_boards_no > 10 && assembled_boards_no <= 50){
      multifly_factor = 0.4;
    }else if(assembled_boards_no > 50 && assembled_boards_no <= 100){
      multifly_factor = 0.3;
    }else if(assembled_boards_no > 100 && assembled_boards_no <= 500){
      multifly_factor = 0.25;
    }
    else if(assembled_boards_no > 500){
      multifly_factor = 0.23; 
    }
    // alert(multifly_factor);

    var price = ((BGA_components_Pads + Throughhole_components_pads * 1.1 + total_BGA_pads * 1.1) * multifly_factor)*assembled_boards_no;
    // alert(price);
    $('#price').text(price);
    $('#No_of_board_to_be_assembled').text(assembled_boards_no);
    $('#multifly_factor_text').text(multifly_factor);
    $("#total_amount").text(
      Math.ceil(price * 1.18)
    );
    // 

    console.log("price :::=> " + price);
    console.log("BGA_components_Pads :::=> " + BGA_components_Pads);
    console.log("Throughhole_components_pads ::: " + Throughhole_components_pads);
    console.log("assembled_boards ::: " + assembled_boards);
    console.log("multifly_factor ::: " + multifly_factor);
    setLoading(false)


  }

  function Handler(event) {
    event.preventDefault();
    const token1 = localStorage.getItem("token");
    var fileInput = document.getElementById("fileInput"); // Replace "fileInput" with the actual ID of your file input element
    var Bill_of_Materials = document.getElementById("Bill_of_Materials"); // Replace "fileInput" with the actual ID of your file input element
    if($("#pcb_name").val() == ''){
      alert('PCB Name is Required.');
      toast.error('PCB Name is Required.')
   
    }else if($("#Throughhole_components_pads").val() == '' || $("#BGA_components").val() == '' || $("#total_BGA_pads").val() == '' || $("#assembled_boards_no").val() == ''){
      alert('All Fields Required.');
      toast.error('All Fields Required.');

    }else if (fileInput.files.length == 0 || Bill_of_Materials.files.length == 0)   {
      alert('Schematic Data & Bill of Materials File is Requied.');
      toast.error('Schematic Data & Bill of Materials File is Requied.')
    } else if (token1 != null) {
      var myHeaders = new Headers();
      myHeaders.append("authorization", token1);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");


      var urlencoded = new FormData();
      urlencoded.append("quantity", "1");
      urlencoded.append("pcb_name", $("#pcb_name").val());
      urlencoded.append("no_of_layers", $("#no_of_layers").val());
      urlencoded.append("pcb_thickness", $("#pcb_thickness").val());
      urlencoded.append("pcb_dimensions", $("#pcb_dimensions_1").val() + ' X ' + $("#pcb_dimensions_2").val());
      urlencoded.append("is_panel_required", $("#is_panel_required_yes").val());
      urlencoded.append("panel_quantity", $("#panel_quantity").val());
      urlencoded.append("panel_dimensions", $("#panel_dimensions_1").val() + ' X ' + $("#panel_dimensions_2").val());
      urlencoded.append("smd_components_pads", $("#BGA_components_Pads").val());

      urlencoded.append("throughhole_components_pads", $("#Throughhole_components_pads").val());
      urlencoded.append("bga_components", $("#BGA_components").val());
      urlencoded.append("bga_pads", $("#total_BGA_pads").val());
      urlencoded.append("board_to_be_assembled", $("#assembled_boards").val());
      urlencoded.append("multifly_factor", $("#multifly_factor_text").text());
      // urlencoded.append("gerber_data", '');
      urlencoded.append("price", $("#price").text());
      urlencoded.append("total_price_with_tex", $("#total_amount").text());
      
      urlencoded.append("assembled_boards_no", $("#assembled_boards_no").val());
      
      urlencoded.append("material", fileInput.files[0]);
      urlencoded.append("bill_of_materials", Bill_of_Materials.files[0]);
      


      var settings = {
        "url": APP_URL2 + "assembly/addCart",
        "method": "POST",
        // "timeout": 0,
        "timeout": 15000,
        "cache": false,
        "processData": false,
        "mimeType": "multipart/form-data",
        "contentType": false,
        "data": urlencoded,
        "headers": {
          "authorization": token1, // Replace "Bearer" with your token type if needed
        }
      };
      $('#Loader').show();
      $.ajax(settings).done(function (response) {
        console.log(response);
        // alert('Enquiry Successfully Submitted.');
        // naviagte('/dashboard');
        // navigate("/cart");
        $('#Loader').hide();
        window.location = '/cart';
      });


      // var requestOptions = {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: urlencoded,
      //   redirect: 'follow'
      // };

      // fetch(APP_URL+"assembly/addCart", requestOptions)
      //   .then(response => response.text())
      //   .then(result => console.log(result))
      //   .catch(error => console.log('error', error));



      navigate("/cart");
    } else {
      navigate("/login");
    }
  }


  const [Note, setNote] = useState(""); // Initializing state for storing fetched data
  function GetNote() {
    const apiUrl = APP_URL2 + "Services_Notes_data_fetch_api/PCB_Assembly";
    console.log(apiUrl);

    fetch(apiUrl)
      .then((response) => {
        console.log('REsponse :::::: ' + response); // Log the entire response
        return response.json();
      })
      .then((data) => {
        setNote(data?.data.contant);
        setLoading(false);
        console.log("Successfully Data Fetch :::: ", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", JSON.stringify(error));
        setLoading(false);
      });
  }

  return (
    <div id="servicemain">
      <div className="container-fluid py-5 service">
        <div className="row">
          <ServiceHeader />
          {
            loading ? <Loader /> : (
              <div className="row">
                <div className="col-lg-8 mb-3">
                  <div className="layout_tabs_main">
                    <div className="row">

                      <div className="col-lg-6">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              PCB Name: <sup>*</sup>
                            </label>
                            <input
                              type="text"
                              className="Layer_custum_css"
                              id="pcb_name"
                              placeholder="PCB Name"
                              aria-describedby="emailHelp" 
                              required
                            />
                          </div>
                        </div>
                      </div>



                      <div className="col-lg-6 mb-3">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              No of Layers: <sup>*</sup>
                            </label>{" "}
                            <select className="w-100 Layer_custum_css" id="no_of_layers">
                              {/* LayerData */}
                              {LayerData.map((item, index) => {
                                return (
                                  <option value={item.option_name} data-target={item.multifly_factor}>{item.option_name}</option>
                                );
                              })}
                              {/* <option>1 Layer</option>
                              <option>2 Layer </option>
                              <option>3 Layer </option>
                              <option>4 Layer </option>
                              <option>5 Layer </option>
                              <option>6 Layer </option>
                              <option>7 Layer </option>
                              <option>8 Layer </option>
                              <option>9 Layer</option>
                              <option>10 Layer  </option> */}
                            </select>
                          </div>
                        </div>
                      </div>



                      <div className="col-lg-6 mb-3 dim" id="show">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              PCB Thickness (mm): <sup>*</sup>
                            </label>
                            <select className="w-100 Layer_custum_css" id="pcb_thickness">
                              {ThicknessData.map((item, index) => {
                                return (
                                  <option value={item.id} data-target={item.multifly_factor}>{item.option_name}</option>
                                );
                              })}
                              {/* <option>0.4</option>
                              <option>0.5</option>
                              <option>0.3</option> */}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6 offset" id="#exampleModal">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                          PCB Dimensions(mm): <sup>*</sup>
                        </label>
                        <div className="Dimensions_input d-flex align-items-center">
                          <input type="text" defaultValue={5} name="f" id="pcb_dimensions_1" />
                          <span>X</span>
                          <input type="text" defaultValue={6} name="ff" id="pcb_dimensions_2" />
                          <span>MM</span>
                        </div>
                      </div>


                      {/* <div className="col-lg-6 mt-3">
                        <label htmlFor="exampleInputEmail1" className="form-label">
                          Panel Required: <sup>*</sup>
                        </label>
                        <br />
                        <div className="radio-button">
                          <input
                            type="radio"
                            name="radio52"
                            id="is_panel_required"
                            defaultChecked=""
                            onClick={() => setshow(false)}
                          />
                          <label htmlFor="is_panel_required">No</label>
                          <>
                            <input
                              type="radio"
                              name="radio52"
                              id="is_panel_required_yes"
                              data-bs-toggle="fim"
                              data-bs-target="#exampleModal"
                              onClick={() => setshow(true)}
                            />
                            <label htmlFor="is_panel_required_yes">Yes</label>
                          </>
                        </div>
                      </div> */}

                      {show && (
                        <>

                          <div className="col-lg-6 offset" id="#exampleModal">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                              Panel Dimensions: <sup>*</sup>
                            </label>
                            <div className="Dimensions_input d-flex align-items-center">
                              <input type="text" defaultValue={5} name="f" id="panel_dimensions_1" />
                              <span>X</span>
                              <input type="text" defaultValue={6} name="ff" id="panel_dimensions_2" />
                              <span>MM</span>
                            </div>
                          </div>


                          <div className="col-lg-6 offset" id="#exampleModal">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                              Panel Quantity: <sup>*</sup>
                            </label>
                              <input className="form-control" type="text" defaultValue={0} name="panel_quantity" id="panel_quantity" />
                          </div>

                        </>

                      )}




                      <div className="col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          No of SMD components Pads exluding BGA components Pads: <sup>*</sup>
                        </label>
                        <input
                          type="text"
                          name="from"
                          defaultValue={0}
                          oninput=""
                          onKeyUp={GetPricing}
                          id="BGA_components_Pads"
                          className="form__field Quantity Layer_custum_css"
                        />
                      </div>

                      <div className="col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          No of Throughhole components pads: <sup>*</sup>
                        </label>
                        <input
                          type="number"
                          name="from"
                          // defaultValue={0}
                          onKeyUp={GetPricing}
                          oninput=""
                          id="Throughhole_components_pads"
                          className="form__field Quantity Layer_custum_css"
                        />
                      </div>

                      <div className="col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          No of BGA components: <sup>*</sup>
                        </label>
                        <input
                          type="number"
                          name="from"
                          // defaultValue={0}
                          onKeyUp={GetPricing}
                          oninput=""
                          id="BGA_components"
                          className="form__field Quantity Layer_custum_css"
                        />
                      </div>
                      <div className="col-lg-6">
                        <label
                          htmlFor="exampleInputEmail1"
                          className="form-label"
                        >
                          Total BGA pads: <sup>*</sup>
                        </label>
                        <input
                          type="number"
                          name="from"
                          // defaultValue={0}
                          onKeyUp={GetPricing}
                          oninput=""
                          id="total_BGA_pads"
                          className="form__field Quantity Layer_custum_css"
                        />
                      </div>

                      <div className="col-lg-6 mb-3">
                        <div className="layout_tabs_main_chile">
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >No of board to be assembled: <sup>*</sup>
                            </label>
                            
                            {/* <select className="w-100 Layer_custum_css" id="assembled_boards" onChange={GetPricing}>
                              {layer.map((item, index) => {
                                return (
                                  <option value={item.id} data-target={item.multifly_factor}>{item.boards_no}</option>
                                );
                              })}
                            </select> */}
                            <input
                              type="number"
                              name="from"
                              defaultValue={1}
                              min={1}
                              oninput=""
                              placeholder="Enter No of Boards Assemble Value"
                              id="assembled_boards_no"
                              className="form__field Quantity Layer_custum_css"
                              // data-target={item.multifly_factor}
                              onChange={GetPricing}
                            />

                          </div>
                        </div>
                      </div>
<div className="row">
                      <div className="col-sm-6 mb-3">
                        <div className="layout_tabs_main_chile" onDrop={handleGerberFileDrop}
                      onDragOver={(event) => event.preventDefault()}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Gerber Data: <sup>*</sup>
                            </label>
                            <div id="wrapper" className="dropify-wrapper">
                              <input
                                type="file"
                                name="f-img"
                                id="fileInput"
                                className="form-control"
                                accept=".zip,.rar"
                                onChange={GerberDataChange}

                              />
                              <div className="dropify-message">
                                <div className="dropify-message-1">
                                  <svg
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                  </svg>
                                  <p>Drag and drop a file here or click <b style={{color:'#33b751'}}>File format: .rar / .zip </b></p>
                                </div>
                                {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {GerberData && (
                        <div>
                          <p className="m-0">Selected File: <b> {GerberData.name} </b></p>
                          <button onClick={handleDeleteFile2} style={{color:'blue'}}> <i class="fa fa-trash" aria-hidden="true"></i> Remove File</button>

                          {/* <p className="m-0">File Size:<b>  {GerberData.size} bytes </b></p>
                          <p className="m-0">File Type:<b>  {GerberData.type} </b></p> */}
                        </div>
                      )}
                      </div>
                      <div className="col-sm-6 mb-3">
                        <div className="layout_tabs_main_chile" onDrop={handleMaterialsFileDrop}
                      onDragOver={(event) => event.preventDefault()}>
                          <div className="mb-3">
                            <label
                              htmlFor="exampleInputEmail1"
                              className="form-label"
                            >
                              Bill of Materials: <sup>*</sup>
                            </label>
                            <div id="wrapper" className="dropify-wrapper">
                              <input
                                type="file"
                                // name="f-img"
                                className="form-control"
                                id="Bill_of_Materials"
                                accept=".xls,.xlsx"
                                onChange={MaterialsChange}
                                // onChange={(e) => setUpload(e.target.files[0])}
                              />
                              <div className="dropify-message">
                                <div className="dropify-message-1">
                                  <svg
                                    width="18px"
                                    height="18px"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                  >
                                    <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                  </svg>
                                  <p>Drag and drop a file here or click <b style={{color:'#33b751'}}>File format: .xls </b></p>
                                </div>
                                {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                              </div>
                            </div>
                          </div>
                        </div>
                        {Materials && (
                        <div>
                          <p className="m-0">Selected File: <b> {Materials.name} </b></p>
                          <button onClick={handleDeleteFile} style={{color:'blue'}}> <i class="fa fa-trash" aria-hidden="true"></i> Remove File</button>

                          {/* <p className="m-0">File Size:<b>  {Materials.size} bytes </b></p>
                          <p className="m-0">File Type:<b>  {Materials.type} </b></p> */}
                        </div>
                      )}
                      </div>
                      </div>
                    <p style={{"background": "#d8d8e1","border-radius": "10px","padding": "20px"}}><i class="fa fa-bullhorn" aria-hidden="true" style={{"font-size": "20px","color":" rgb(51, 183, 81)"}}></i> {Note}</p>

                      {/* <div className="col-sm-4 mb-3">
                  <div className="layout_tabs_main_chile">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Bill of Material: <sup>*</sup>
                      </label>
                      <div id="wrapper" className="dropify-wrapper">
                        <input
                          type="file"
                          name="f-img"
                          className="form-control"
                        />
                        <div className="dropify-message">
                          <div className="dropify-message-1">
                            <svg
                              width="18px"
                              height="18px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                            </svg>
                            <p>Drag and drop a file here or click</p>
                          </div>
                          {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                      {/* </div>
                      </div>
                    </div>
                  </div>
                </div> * */}

                      {/* <div className="col-sm-4 mb-3">
                  <div className="layout_tabs_main_chile">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Pick &amp; Place: <sup>*</sup>
                      </label>
                      <div id="wrapper" className="dropify-wrapper">
                        <input
                          type="file"
                          name="f-img"
                          className="form-control"
                        />
                        <div className="dropify-message">
                          <div className="dropify-message-1">
                            <svg
                              width="18px"
                              height="18px"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                            >
                              <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                            </svg>
                            <p>Drag and drop a file here or click</p>
                          </div>
                          {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                      {/* </div>
                      </div>
                    </div>
                  </div>
                </div>  */}
                      {/* <div className="col-sm-8">
                    <div className="add_to_card " style={{marginTop:'3.5rem',float:'right'}} >
                      <button
                        type="submit"
                        className="btn btn-primary m-3 p-2  "
                        onClick={Handler}
                        id="bn"
                        style={{width:'20rem',}}
                        
                      >
                        Process to Checkout
                      </button>
                    </div>
                  </div> */}

                      <li className="list-item">

                        <button
                          className="button button--promo"
                          onClick={Handler}
                        >
                          Add to Cart
                        </button>
                      </li>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="layout_tabs_main">
                    <div className="calculated_price_heading">
                      <h5 className="text-center w-100 text-green fontw-600 mb-0">
                        Calculated Price (₹)
                      </h5>
                    </div>
                    <div className="mt-4">
                      <div className="d-flex"> <p id="bord_assembled"><b>No of board to be assembled:</b> </p>  <p className="ms-auto" id="No_of_board_to_be_assembled"> -- </p> </div>
                      {/* <div className="d-flex"> <p>Multifly Factor : </p>  <p className="ms-auto" id="multifly_factor_text"> -- </p> </div> */}
                    <hr className="price-hr" />

                      <div className="d-flex"> <p><b>Order Value:</b> </p>  <p className="ms-auto" id="price"> -- </p> </div>
                    <hr className="price-hr" />

                      <div className="d-flex"> <p><b>Tax(%): </b></p>  <p className="ms-auto" > 18 </p> </div>
                    </div>
                    <hr className="price-hr" />
                    <div className="d-flex"> <p><b>Total Price:</b> </p>  <p className="ms-auto" id="total_amount" >  </p> </div>
                    <div className="text-center bottom-10 d-flex justify-content-center align-items-center">

                      {/* <a type="submit" className="button button--green top-20u">
                        <span>Compare prices</span>
                      </a> */}
                    </div>
                  </div>
                </div>
              </div>

            )
          }

        </div>
      </div>
    </div>
    // </div>
  );
};

export default Pcbassembly;
