import google1 from "./images/google1.png";
import ReCAPTCHA from "react-google-recaptcha";
import { Link, json, useNavigate } from "react-router-dom";
import { useState } from "react";

// Importing toastify module
import { ToastContainer, toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import Chekout from "../Chekout";

function Reg2() {
  const naviagate = useNavigate();
  const [Captcha, setCaptcha] = useState("");

  function onChange(value) {
    // alert(value);
    setCaptcha(value);
    console.log("Captcha value:", value);
  }

  const [firstName, setFirst] = useState("");
  const [lastName, setLast] = useState("");
  const [email, setEmail] = useState("");
  const [emailConf, setEmailConf] = useState("");
  const [mess, setMess] = useState("");

  function handleForm(e) {
    
    e.preventDefault();

    if(Captcha == ''){
      // alert('Please Check Captcha. Captcha is Required.'); 
      toast.error("Please Check Captcha. Captcha is Required. 😥");
      return;
    }
    const formData = { firstName, lastName, email, emailConf };
    // console.log(formData)
    fetch(APP_URL + "registration", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(formData),
    }).then((res) => {
        return res.json();
      })
      .then((data) => {
        console.log(data);
        if (firstName === "") {
          toast.error("First Name is required 😥");
        } else if (lastName === "") {
          toast.error("Last Name is required 😥");
        }
        else if (email ===""|| emailConf===""){
            toast.error("Email is Required 🙂 ")
        }
        else if (email !== emailConf) {
          toast.error("Email must match 😕");
        } else if (email === emailConf) {
          toast.success("Successful");
          setTimeout(() => {
            naviagate("/emailVerify");
          }, 4000);
        } else {
          setMess("Something Went Wrong. Please try Again Later");
        }
      });
      
     

    // console.log(formData)
  }

  return (
    <>
      <section className="registration">
        <div className="row" style={{ justifyContent: "center" }}>
          <div className="col-md-8 col-xl-6  login-container">
            <h4 className="heading-h1" style={{ paddingTop: "50px" }}>
              Registration
            </h4>

            <form
              autoComplete="off"
              className="form edit-profiles top-50"
              method="post"
              onSubmit={(e) => {
                handleForm(e);
              }}
            >
              <div className="row">
                <ToastContainer />
                <div className="col-md-6 mb-3">
                  <div className="from-group">
                    <i className="fa fa-user" />
                    <input
                      autoComplete="off"
                      type="text"
                      name="firstname"
                      id="firstname"
                      placeholder="Enter Your First Name"
                      className="form__field border-bottom-right-round empty iconified"
                      value={firstName}
                      onChange={(e) => {
                        setFirst(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="from-group">
                    <i className="fa fa-user" />
                    <input
                      autoComplete="off"
                      type="text"
                      name="lastname"
                      id="lastname"
                      placeholder="Enter Your Last Name"
                      className="form__field border-bottom-right-round empty iconified"
                      value={lastName}
                      onChange={(e) => {
                        setLast(e.target.value);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 ">
                  <div className="from-group mb-3">
                    <i className="fa fa-envelope" />
                    <input
                      autoComplete="off"
                      type="email"
                      name="emailReg"
                      id="emailReg"
                      placeholder="Email"
                      className="form__field border-bottom-right-round empty iconified"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                    />
                    {/**/}
                  </div>
                  <div className="from-group">
                    <i className="fa fa-envelope" />
                    <input
                      autoComplete="off"
                      type="email"
                      name="emailReg"
                      id="emailReg"
                      placeholder="Confirm Email"
                      className="form__field border-bottom-right-round empty iconified"
                      value={emailConf}
                      onChange={(e) => {
                        setEmailConf(e.target.value);
                      }}
                    />
                  </div>
                  
                </div>
              </div>

              {/* <div id="ReCaptchContainer" className="m-t-15" style={{ height: '78px', textAlign: 'center' }}>
                                <div style={{ width: '304px', height: '78px' }}><div>
                                    <iframe title="reCAPTCHA" width={304} height={78} role="presentation" name="a-3j35lmwkebaa" frameBorder={0} scrolling="no" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-top-navigation allow-modals allow-popups-to-escape-sandbox" />
                                </div>
                                </div>
                                <iframe style={{ display: 'none' }} />
                            </div> */}
              <div className="col">
                <div className="form-check" style={{ display: "flex" }}>
                  <input
                    name="checkbox"
                    id="as"
                    req
                    value="yes"
                    type="checkbox"
                    style={{ position: "relative", right: "1rem" }} required  />
                  <label
                    className="form-check-label"
                    style={{
                      display: "inline",
                      position: "relative",
                      left: "-0.7rem",
                      top: "0.7rem",
                    }}
                  >
                    Accept Terms & Condition
                  </label>
                </div>
              </div>

              <div className="row" style={{ marginTop: "20px" }}>
                <div className="col-md-6">
                  <ReCAPTCHA
                    sitekey="6LcO10QpAAAAAPDJuScfG1g6YSyMPmMHVsrT-MX0"
                    onChange={onChange}
                    aria-required
                  />
                </div>
                
                <div className="col-md-6">
                  <button
                    id="selectRegister"
                    type="submit"
                    className="button--promo "
                    style={{
                      width: "220px",
                      display: "inline",
                      backgroundColor: "#f1aa44",
                      padding: "1rem",
                      height: "3rem",
                    }}
                  >
                    {/* <span href="/emailVerify" >Register Now</span>
                     */}
                    Register
                  </button>
                </div>


              </div>
              <div
                className="row"
                style={{
                  position: "relative",
                  left: "25%",
                  marginTop: "20px",
                  letterSpacing: "1.5",
                }}
              >
                <span id="GotologinPage">
                  Already have an Account?
                  <Link to="/login">
                    <span className="color--green"> Login </span>
                  </Link>
                </span>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}

export default Reg2;
