import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Sidebar from './pages/dashboardsidebar';
import $ from 'jquery';
// import { useNavigate } from "react-router-dom";




function EditPage() {
  const [mess, setMess] = useState('')
  const [registeras, setCompany] = useState('')
  const [company_name, setcompany_name] = useState('')
  const [url, seturl] = useState('')
  const [password, setPass] = useState('')
  const [rePass, setRepass] = useState('')
  const [mobileno, setMblNo] = useState('')
  const [gst, setGstNo] = useState('')
  const [alternateno, setalternateno] = useState('')
  const [industries, setindustries] = useState('')
  const [companytype, setCompnyType] = useState('')
  const [information, setSourOfInfo] = useState('')
  const [address1, setAddress1] = useState('')
  const [address2, setAddress2] = useState('')
  const [pincode, setPincode] = useState('')
  const [city, setCity] = useState('')
  const [districk, setdistrick] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')

  const [firstname, setFirstname] = useState("")
  const [email, setemail] = useState("")
  const [data, setdata] = useState('')
  const [token, settoken] = useState('')
  // setdata('[]');
  let navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem("token")
    console.log(token, "token for cart")
    const url = APP_URL + 'userProfile'
    console.log(url);
    console.log(APP_URL);
    settoken(token)

    fetch(APP_URL + 'userProfile', {
      headers: {
        Authorization: `${token}`
      }
    }).then((res) => res.json()).then((data) => {

      // console.log(data,"value of cart")
      console.log(data, 'data')
      setdata(data.message[0])
      const lvlup = data.message.filter((item) => {
        localStorage.setItem('name', item.firstname)
        console.log(item.firstname);
      })
    })

  }, [])
  // 

  function userProfileEditPost() {
    event.preventDefault();
    // alert('submitted');
    var token = localStorage.getItem('token');
    var myHeaders = new Headers();
    myHeaders.append("authorization", token);
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("firstname", $('#firstname').val());
    urlencoded.append("company_name", $('#company_name').val());
    urlencoded.append("url", $('#url').val());
    urlencoded.append("email", $('#email').val());
    urlencoded.append("password", $('#password').val());
    urlencoded.append("rePass", $('#rePass').val());
    urlencoded.append("mobileno", $('#mobileno').val());
    urlencoded.append("alternateno", $('#alternateno').val());
    urlencoded.append("authorization", token);



    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: urlencoded,
      redirect: 'follow'
    };

    fetch(APP_URL+"userProfileEditPost", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));

      navigate("/userProfile");
  }

  return (
    <>

      <div className="user-profile">
        <form action="" id="userProfileEditPost">
          <input type="hidden" name="token" value={token} />
          <div className="container py-4 d-flex align-items-start">
            {/* <div className="row">
                    <div className="col-2">
                        <div className="overview_box py-2">
                            <h3>Overview</h3>
                        </div>
                        <hr className="border_hr" />
                        <div
                            className="nav flex-column nav-pills overview_box me-3"
                            id="v-pills-tab"
                            role="tablist"
                            aria-orientation="vertical"
                        >
                            <h3 className="mb-0">ORDERS</h3>
                            <button
                                className="nav-link"
                                id="My_Orders"
                                data-bs-toggle="pill"
                                data-bs-target="#My_Orders-tab"
                                type="button"
                                role="tab"
                                aria-controls="My_Orders"
                                aria-selected="true"
                            >
                                My Orders
                            </button>
                            <button
                                className="nav-link"
                                id="shipped-Orders-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#shipped-Orders"
                                type="button"
                                role="tab"
                                aria-controls="shipped-Orders"
                                aria-selected="false"
                            >
                                shipped Orders
                            </button>
                            <button
                                className="nav-link"
                                id="Exceptions-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#Exceptions"
                                type="button"
                                role="tab"
                                aria-controls="Exceptions"
                                aria-selected="false"
                            >
                                Exceptions
                            </button>
                            <button
                                className="nav-link"
                                id="v-pills-settings-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-settings"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-settings"
                                aria-selected="false"
                            >
                                Excalation
                            </button>
                            <hr className="border_hr " />
                            <h3 className="mb-0">ACCOUNT</h3>
                            <button
                                className="nav-link active"
                                id="v-pills-home-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-home"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-home"
                                aria-selected="true"
                            >
                                User Profile
                            </button>
                            <button
                                className="nav-link"
                                id="v-pills-profile-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-profile"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-profile"
                                aria-selected="false"
                            >
                                Address Management
                            </button>
                            <button
                                className="nav-link"
                                id="v-pills-messages-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#v-pills-messages"
                                type="button"
                                role="tab"
                                aria-controls="v-pills-messages"
                                aria-selected="false"
                            >
                                Email Management
                            </button>
                            <hr className="border_hr " />
                            <h3 className="mb-0">TRACKING</h3>
                            <button
                                className="nav-link"
                                id="Track-Inquiry-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#Track-Inquiry"
                                type="button"
                                role="tab"
                                aria-controls="Track-Inquiry"
                                aria-selected="true"
                            >
                                Track Inquiry
                            </button>
                            <button
                                className="nav-link"
                                id="Track-Quotation-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#Track-Quotation"
                                type="button"
                                role="tab"
                                aria-controls="Track-Quotation"
                                aria-selected="false"
                            >
                                Track Quotation
                            </button>
                            <button
                                className="nav-link"
                                id="Email-Management_sec-tab"
                                data-bs-toggle="pill"
                                data-bs-target="#Email-Management_sec"
                                type="button"
                                role="tab"
                                aria-controls="Email-Management_sec"
                                aria-selected="false"
                            >
                                Email Management
                            </button>
                        </div>
                    </div>
                </div> */}
            <Sidebar />

            <div className="row">

              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    First Name: <sup>*</sup>
                  </label>
                  <i className="fa fa-user" />
                  <input
                    type="text"
                    name="firstname1"
                    id="firstname"
                    placeholder="Enter Fname"
                    className="form_group_select_box"
                    value={data.firstname}
                    onChange={(e) => setdata({ ...data, firstname: e.target.value })}
                  // onChange={(e)=>setFirstname(e.target.value)}

                  />
                </div>
              </div>

              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Company Name: <sup>*</sup>
                  </label>
                  <i className="fa fa-building" />
                  <input
                    type="text"
                    name="company_name"
                    placeholder="Enter Your Company Name"
                    className="form_group_select_box"
                    id="company_name"
                    value={data.company_name}
                    onChange={(e) => setdata({ ...data, company_name: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">Company URL:</label>
                  <i className="fa fa-link" />
                  <input
                    type="text"
                    name="CompURL"
                    placeholder="Enter Your Company URL"
                    className="form_group_select_box"
                    id="url"

                    value={data.url}
                    onChange={(e) => setdata({ ...data, url: e.target.value })}


                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Email: <sup>*</sup>
                  </label>
                  <i className="fa fa-envelope" />
                  <input
                    type="text"
                    name="emailReg"
                    placeholder="Enter Your Email"
                    className="form_group_select_box"
                    // readOnly={email}
                    id="email"
                    disabled
                    value={data.email}
                    // onChange={(e) => setdata({ ...data, email: e.target.value })}

                  // onChange={(e)=>setEmail(e.target.value)}

                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Password: <sup>*</sup>
                  </label>
                  <i className="fa fa-lock" />
                  <input
                    type="password"
                    name="password"
                    placeholder="Enter Your Password"
                    className="form_group_select_box"
                    id="password"

                  // value={data.password}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Re-Enter Password: <sup>*</sup>
                  </label>
                  <i className="fa fa-lock" />
                  <input
                    type="password"
                    name="confirmpass"
                    placeholder="Re-Enter Your Password"
                    className="form_group_select_box"
                    id="rePass"

                    onChange={(e) => setRepass(e.target.value)}
                  // value={data.rePass}
                  />
                </div>
              </div>
              <div className="col-md-6 mt-4">
                <div className="form_group_1">
                  <label className="form__label">
                    Mobile Nr: <sup>*</sup>
                  </label>
                  <i className="fa fa-phone" />
                  <input
                    type="text"
                    name="mobilenr"
                    placeholder="Enter Mobile Nr"
                    className="form_group_select_box"
                    id="mobileno"
                    value={data.mobileno}
                    onChange={(e) => setdata({ ...data, mobileno: e.target.value })}

                  />
                </div>
              </div>
              <div className="col-md-6 mt-4 ">
                <div className="form_group_1">
                  <label className="form__label">
                    Alternate Nr: <sup>*</sup>
                  </label>
                  <i className="fa fa-phone" />
                  <input
                    type="text"
                    name="alternr"
                    placeholder="Enter Alternate Nr"
                    className="form_group_select_box"
                    id="alternateno"
                    value={data.alternateno}
                    onChange={(e) => setdata({ ...data, alternateno: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-4 py-4">
                <button className="btn btn-dark py-1 w-100" onClick={userProfileEditPost}>Save</button>
              </div>
            </div>

            <div className="row">

            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default EditPage;