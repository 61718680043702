import React, { useContext } from "react";
import ServiceHeader from "./ServiceHeader";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import $, { error } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import { format } from "date-fns";


// import Chekout from "../Chekout";
import Reg2 from "../Reg2";
import { all } from "axios";

import { Link, Navigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import myContext from "../mycontext";

const Pcblayout = () => {
  const context = useContext(myContext)
  console.log(context);

  const { show, setshow } = context
  console.log(show);;
  const [days, setDays] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pcbName, setPcbname] = useState("");
 
  console.log(pcbName);
  const [selectedComponents, setSelectedComponents] = useState({
    price: 0,
    days: 0,
  });

  const [layer, setlayer] = useState([]);
  const [lead_time_html, setlead_time_html] = useState([]);
  const [Component_html, setComponent_html] = useState([]);
  const [selectedData, setselectedData] = useState([]);
  const [data, setData] = useState([]);
  // console.log(layer,"this is real la yer ")
  const [components, setComponents] = useState([]);
  let navigate = useNavigate();

  const [Materials, setMaterials] = useState(null);
  const [SchematicFile, setSchematicFile] = useState(null);
  
  const MaterialsChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    if(fileExtension == 'xls' || fileExtension == 'xlsx'){
      setMaterials(file);
    }else{
      setMaterials(null);
      alert("Please Select xls or xlsx File Only.")
    }
    // setMaterials(file);
  };

  const SchematicChange = (event) => {
    const file = event.target.files[0];
    const fileName = file.name;
    const fileExtension = fileName.split('.').pop();
    if(fileExtension == 'zip' || fileExtension == 'rar'){
      setSchematicFile(file);
    }else{
      setSchematicFile(null);
      alert("Please Select zip or rar File Only.")
    }
    // setSchematicFile(file);
  };

  const handleDeleteFile2 = () => {
    // Remove the selected file
    setSchematicFile(null);
  };
  const handleDeleteFile = () => {
    // Remove the selected file
    setMaterials(null);
   
  };

  useEffect(() => {
    GetNote();
    const apiUrl = APP_URL2 + "get_pcb_layer_layout_data"; // Replace this with your API endpoint URL
    console.log(apiUrl);

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)

      .then((response) => response.json())
      .then((data) => {

        setData(data?.data);
        console.log(data.data);
        setlayer(data?.data[0]);
        setComponent_html(data.Component_html);
        setlead_time_html(data.lead_time_html);
        GetPricing();
        console.log(data.data[0]);

      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  }, []);

  // useEffect(() => {
  //   const apiUrl = APP_URL2 + "getAllPcbLayoutComponent"; // Replace this with your API endpoint URL

  //   // Fetch data from the API using the fetch() function
  //   fetch(apiUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setComponents(data.message);
  //       console.log(data);
  //       // console.log(data.message)
  //       setLoading(false); // Set loading state to false
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       setLoading(false); // Set loading state to false even in case of an error
  //     });
  // }, []);

  // useEffect(() => {
  //   const apiUrl = APP_URL + "getAllPcbLayoutDay"; // Replace this with your API endpoint URL
  //   console.log(apiUrl);

  //   // Fetch data from the API using the fetch() function
  //   fetch(apiUrl)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setDays(data.message);
  //       // console.log(days)
  //       //   console.log(data.message)
  //       setLoading(false); // Set loading state to false
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching data:", error);
  //       setLoading(false); // Set loading state to false even in case of an error
  //     });
  // }, []);

  const Handel = (id) => {
    const singleData = APP_URL2 + "get_pcb_layer_layout_data/" + id; // Replace this with your API endpoint URL
    console.log(singleData);
    fetch(singleData)
      .then((response) => response.json())
      .then((SingleData) => {

        // alert(JSON.stringify(SingleData));
        console.log(SingleData?.data[0]);
        setlayer(SingleData);

        setComponent_html(SingleData.Component_html);
        setlead_time_html(SingleData.lead_time_html);
        GetPricing();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  };

  const componentsData = (id) => {
    const componentsData =
      APP_URL2 +
      "get_pcb_layouts_lead_time_data_with_component_id/" +
      id.replace(/"([^"]+(?="))"/g, "$1"); // Replace this with your API endpoint URL
    console.log(componentsData);

    fetch(componentsData)
      .then((response) => response.json())
      .then((componentsData) => {
        // alert(JSON.stringify(componentsData));

        // console.log(componentsData?.data[0]);
        // setselectedData(componentsData);
        console.log(componentsData);
        setlead_time_html(componentsData.lead_time_html);
        // $('#lead_time').html(componentsData.lead_time_html);
        GetPricing();
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading state to false even in case of an error
      });
  };
  const currentDate = new Date();

  function GetPricing() {
    setTimeout(function () {
      var selectedLayer = $("#LayerId").val();
      var selectedComponenet = $("#componentId").val();
      var selectedLeadTime = $("#lead_time").val();
      //  alert(selectedLayer);
      const MainData =
        // eslint-disable-next-line no-undef
        APP_URL2 +
        "get_pcb_layouts_lead_time_data_get_Pricing/" +
        selectedLeadTime.replace(/"([^"]+(?="))"/g, "$1"); // Replace this with your API endpoint URL
      console.log(MainData);
      fetch(MainData)
        .then((response) => response.json())
        .then((MainData) => {
          // alert(JSON.stringify(MainData));
          // console.log(MainData?.data[0]);
          setselectedData(MainData);
          const newDate = addBusinessDays(currentDate, MainData.leadTime[0].lead_time);
          $('#ShipmentDate').text(format(newDate, "yyyy-MM-dd"));
          // alert(newDate);
          //
          //
          // selectedData.leadTime[0].price
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          setLoading(false); // Set loading state to false even in case of an error
        });
    });
  }
  setTimeout(function () {
    $("#cal_Lead_Times").text(selectedData.leadTime[0].lead_time);
    $("#OrderValue").text(selectedData.leadTime[0].price);
    $("#TotalOrderPrice").text(
      Math.ceil(selectedData.leadTime[0].price * 1.18)
    );
  }, 1000);

  function Handler(event) {
    event.preventDefault();
   
    const token1 = localStorage.getItem("token");
    // console.log(fileInput);
    // alert()
    var fileInput = document.getElementById("fileInput"); // Replace "fileInput" with the actual ID of your file input element
    var Bill_of_Materials = document.getElementById("Bill_of_Materials"); // Replace "fileInput" with the actual ID of your file input element
    
    if (pcbName == "") {
      toast.error('PCB Name is reqiured')
    } else if(fileInput.files.length == 0 || Bill_of_Materials.files.length == 0){
      toast.error('Schematic Data & Bill of Materials File is Requied')
    }
    else if (token1 != null) {
      var myHeaders = new Headers();
      myHeaders.append("authorization", token1);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

     
      var urlencoded = new FormData();
      urlencoded.append("quantity", "1");
      urlencoded.append("pcb_name", $("#pcb_name").val());
      urlencoded.append("pcb_layout_id", $("#LayerId").val());
      urlencoded.append("layer", $("#LayerId option:selected").text());
      urlencoded.append("components", $("#componentId option:selected").text());
      urlencoded.append("lead_time", $("#lead_time option:selected").text());
      urlencoded.append("price", $("#OrderValue").text());
      urlencoded.append("total_price_with_tex", $("#TotalOrderPrice").text());
      urlencoded.append("shipment_date", $("#ShipmentDate").text());
      // urlencoded.append("schematic", "1");
      urlencoded.append("material", fileInput.files[0]);
      urlencoded.append("bill_of_materials", Bill_of_Materials.files[0]);


      var settings = {
        "url": APP_URL2 + "layout/addCart",
        "method": "POST",
        // "timeout": 0,
        "timeout": 15000,
        "cache": false,
        "processData": false,
        "mimeType": "multipart/form-data",
        "contentType": false,
        "data": urlencoded,
        "headers": {
          "authorization": token1, // Replace "Bearer" with your token type if needed
        }
      };
      $('#Loader').show();
      $.ajax(settings).done(function (response) {
        console.log(response);
        $('#Loader').hide();
        navigate("/cart");
        // alert('Enquiry Successfully Submitted.');
        // naviagte('/dashboard');
      });

      // var requestOptions = {
      //   method: 'POST',
      //   headers: myHeaders,
      //   body: urlencoded,
      //   redirect: 'follow',
      //   "mimeType": "multipart/form-data",
      // };

      // fetch(APP_URL + "layout/addCart", requestOptions)
      //   .then(response => response.text())
      //   .then(result => console.log(result))
      //   .catch(error => console.log('error', error));

     
    } else {
      navigate("/login");
    }
  }
  function OPP() {
    setshow(!show)
  }

  const handleMaterialsFileDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setMaterials(droppedFile);
    // alert(selectedFile.name);
  };
  const handleSchematicFileDrop = (event) => {
    event.preventDefault();
    const droppedFile = event.dataTransfer.files[0];
    setSchematicFile(droppedFile);
    // alert(selectedFile.name);
  };
  
  function addBusinessDays(currentDate, daysToAdd) {
    const weekends = [0, 6]; // 0 is Sunday, 6 is Saturday
    let date = new Date(currentDate);

    while (daysToAdd > 0) {
      date.setDate(date.getDate() + 1); // Add one day

      // Check if the current day is not a weekend day (Sunday or Saturday)
      if (!weekends.includes(date.getDay())) {
        daysToAdd--;
      }
    }

    return date;
  }
  
  const [Note, setNote] = useState(""); // Initializing state for storing fetched data
  function GetNote() {
    const apiUrl = APP_URL2 + "Services_Notes_data_fetch_api/PCB_Layout";
    console.log(apiUrl);

    fetch(apiUrl)
      .then((response) => {
        console.log('REsponse :::::: ' + response); // Log the entire response
        return response.json();
      })
      .then((data) => {
        setNote(data?.data.contant);
        setLoading(false);
        console.log("Successfully Data Fetch :::: ", data);
      })
      .catch((error) => {
        console.error("Error fetching data:", JSON.stringify(error));
        setLoading(false);
      });
  }
  return (
    <div id="servicemain">
      <div className="container-fluid py-5 service">
        <div className="row">
          <ServiceHeader />
          <ToastContainer />
          <form action="#" method="get">
            <div className="row mt-4">
              <div className="col-lg-8">
                <div className="layout_tabs_main mb-4">
                  <div className="row">
                    <div className="col-sm-4 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            PCB Names: <sup>*</sup>
                          </label>
                          <input
                            type="text"
                            className="Layer_custum_css"
                            id="pcb_name"
                            placeholder="PCB Name"
                            aria-describedby="emailHelp"
                            value={pcbName}
                            onChange={(e) => setPcbname(e.target.value)}
                            required
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            Layer: <sup>*</sup>
                          </label>
                          <select
                            class="w-100 Layer_custum_css"
                            id="LayerId"
                            onChange={(e) => Handel(e.target.value)}
                            required
                          >
                            {data.map((item, index) => {
                              return (
                                <option value={item.id}>{item.layer}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-4 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            No. of Components: <sup>*</sup>
                          </label>
                          <div>
                            <select
                              class="w-100 Layer_custum_css"
                              id="componentId"
                              onChange={(e) => componentsData(e.target.value)}
                              required
                            >
                              {/* <option value="">test</option> */}
                              {ReactHtmlParser(Component_html)}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-8 mb-3">
                      <div className="layout_tabs_main_chile">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                            lead time: <sup>*</sup>
                          </label>
                          <div>
                            <select
                              class="w-100 Layer_custum_css"
                              id="lead_time"
                              required
                            >
                              {ReactHtmlParser(lead_time_html)}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile" onDrop={handleSchematicFileDrop}
                      onDragOver={(event) => event.preventDefault()}>
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                             Schematic Data: <sup>*</sup>
                          </label>
                          <div id="wrapper" className="dropify-wrapper">
                            <input
                              type="file"
                              // name="f-img"
                              className="form-control"
                              id="fileInput"
                              accept = ".zip,.rar"
                              required
                            // onChange={(e) => setUpload(e.target.files[0])}
                            onChange={SchematicChange}
                            />
                            <div className="dropify-message">
                              <div className="dropify-message-1">
                                <svg
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                </svg>
                                <p>Drag and drop a file here or click <b style={{color:'#33b751'}}>File format: .zip /.rar </b></p>
                              </div>
                              {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {SchematicFile && (
                        <div>
                          <p className="m-0">Selected File: <b> {SchematicFile.name} </b></p>
                          <button onClick={handleDeleteFile2} style={{color:'blue'}}> <i class="fa fa-trash" aria-hidden="true"></i> Remove File</button>
                          {/* <p className="m-0">File Size:<b>  {SchematicFile.size} bytes </b></p>
                          <p className="m-0">File Type:<b>  {SchematicFile.type} </b></p> */}
                        </div>
                      )}
                    </div>
                    
                    <div className="col-sm-6 mb-3">
                      <div className="layout_tabs_main_chile" onDrop={handleMaterialsFileDrop}
                      onDragOver={(event) => event.preventDefault()}>
                     
                        <div className="mb-3">
                          <label
                            htmlFor="exampleInputEmail1"
                            className="form-label"
                          >
                             Bill of Materials: <sup>*</sup>
                          </label>
                          <div id="wrapper" className="dropify-wrapper">
                            <input
                              type="file"
                              // name="f-img"
                              onChange={MaterialsChange}
                              className="form-control"
                              id="Bill_of_Materials"
                              accept = ".xls,.xlsx"
                              required
                            // onChange={(e) => setUpload(e.target.files[0])}
                            />
                            <div className="dropify-message">
                              <div className="dropify-message-1">
                                <svg
                                  width="18px"
                                  height="18px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M13 13V18.585L14.8284 16.7574L16.2426 18.1716L12 22.4142L7.75736 18.1716L9.17157 16.7574L11 18.585V13H13ZM12 2C15.5934 2 18.5544 4.70761 18.9541 8.19395C21.2858 8.83154 23 10.9656 23 13.5C23 16.3688 20.8036 18.7246 18.0006 18.9776L18.0009 16.9644C19.6966 16.7214 21 15.2629 21 13.5C21 11.567 19.433 10 17.5 10C17.2912 10 17.0867 10.0183 16.8887 10.054C16.9616 9.7142 17 9.36158 17 9C17 6.23858 14.7614 4 12 4C9.23858 4 7 6.23858 7 9C7 9.36158 7.03838 9.7142 7.11205 10.0533C6.91331 10.0183 6.70879 10 6.5 10C4.567 10 3 11.567 3 13.5C3 15.2003 4.21241 16.6174 5.81986 16.934L6.00005 16.9646L6.00039 18.9776C3.19696 18.7252 1 16.3692 1 13.5C1 10.9656 2.71424 8.83154 5.04648 8.19411C5.44561 4.70761 8.40661 2 12 2Z" />
                                </svg>
                                <p>Drag and drop a file here or click <b style={{color:'#33b751'}}>File format: .xls</b></p>
                              </div>
                                {/* <p className="dropify-error">Ooops, something wrong appended.</p> */}
                            </div>
                          </div>
                        </div>
                      </div>
                      {Materials && (
                        <div>
                          <p className="m-0">Selected File: <b> {Materials.name} </b></p>
                          <button onClick={handleDeleteFile} style={{color:'blue'}}> <i class="fa fa-trash" aria-hidden="true"></i> Remove File</button>
                          {/* <p className="m-0">File Size:<b>  {Materials.size} bytes </b></p>
                          <p className="m-0">File Type:<b>  {Materials.type} </b></p> */}
                        </div>
                      )}
                    </div>
                    
                    <p style={{"background": "#d8d8e1","border-radius": "10px" ,"padding": "20px"}}><i class="fa fa-bullhorn" aria-hidden="true" style={{"font-size": "20px","color":" rgb(51, 183, 81)"}}></i> {Note}</p>

                    <li className="list-item">
                      <button
                      type="submit"
                        className="button button--promo"
                        onClick={(e) => {
                          OPP(!show);
                          Handler(e);
                        }}>
                         Add to Cart
                      </button>
                    </li>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="layout_tabs_main">
                  <div className="calculated_price_heading">
                    <h5 className="text-center w-100 text-green fontw-600 mb-0">
                      Calculated Price (₹)
                    </h5>
                  </div>
                  <div className="stencil_calculated_price calculated_price_padding">
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Lead Time:</label>
                      <label className="price-content" id="cal_Lead_Times">
                        {/* {ReactHtmlParser(selectedData.leadTime[0].lead_time)} */}
                      </label>
                      <label id="stencil-label">WD</label>
                    </div>
                    {/* <hr className="price-hr" />
                                            <div className="d-flex justify-content-between">
                                                <label className="price-title">Components:</label>
                                                <label id="OrderValue" className="price-content">
                                                    {compPrice}
                                                </label>
                                            </div>{" "} */}
                    {/* <hr className="price-hr" />
                                            <div className="d-flex justify-content-between">
                                                <label className="price-title">Days:</label>
                                                <label id="OrderValue" className="price-content">
                                                    {price}
                                                </label>
                                            </div>{" "} */}
                    {/* <hr className="price-hr" />
                                            <div className="d-flex justify-content-between">
                                                <label className="price-title">Layer:</label>
                                                <label id="OrderValue" className="price-content">
                                                    {layerPrice}
                                                </label>
                                            </div>{" "} */}
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Order Value:</label>
                      <label id="OrderValue" className="price-content">
                        {/* {ReactHtmlParser(selectedData.leadTime[0].price)} */}
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Tax(%):</label>
                      <label id="OrderTax" className="price-content">
                        18
                      </label>
                    </div>
                    <hr className="price-hr" />
                    <div className="d-flex justify-content-between">
                      <label className="price-title">Total Price:</label>
                      <label
                        id="TotalOrderPrice"
                        className="price-content total-price"
                      >
                        {/* {Math.ceil(selectedData.leadTime[0].price * 1.18)} */}
                      </label>
                    </div>
                    <hr className="price-hr" />{" "}
                    <div className="d-flex justify-content-between">
                      <label className="price-title">
                        Tentative Shipment Date:
                      </label>{" "}
                      <label id="ShipmentDate" className="price-content">
                        {new Date(
                          new Date().setDate(
                            new Date().getDate() +
                            parseInt(selectedComponents.days)
                          )
                        )
                          .toISOString()
                          .slice(0, 10)}
                      </label>
                    </div>{" "}
                    <hr className="price-hr" />
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Pcblayout;
