import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import dateFormat from 'dateformat';
import Sidebar from '../dashboardsidebar';



function Orders() {
    const navigate = useNavigate()
    
    const [profile, setProfile] = useState([])
    const [OrderData, setOrderData] = useState([])
    const [FabricationData, setFabricationData] = useState([])
    const [component_sourcingData, setcomponent_sourcingData] = useState([])

    const token = localStorage.getItem("token")
    useEffect(() => {
        getOrders('orders');
    }, [])

    function getOrders(type) {
        // alert(type);
        const token = localStorage.getItem("token")
        console.log(token, "token for cart")
        const url = APP_URL + 'userProfile'
        console.log(url);
        console.log(APP_URL);

        var myHeaders = new Headers();
        myHeaders.append("authorization", token);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(APP_URL + "my/orders?type="+type, requestOptions)
            .then((response) => response.json())
            .then((orderResult) => {
                console.log('OrderData:::::::'.orderResult);
                if(orderResult.type == 'orders'){
                    setOrderData(orderResult.OrderData);
                }else{
                    setOrderData(null);
                    setFabricationData(orderResult.fabricationData);
                    setcomponent_sourcingData(orderResult.component_sourcingData);
                }

            })
            .catch((error) => console.log("error", error));
    }

    console.log("Order Data Get Successfully ::: ", JSON.stringify(FabricationData));

    function Edit(id) {
        navigate("/Edit/" + id)
    }
    function Editp(id) {
        navigate("/Editpage/" + id)
    }
    function ViewOrder(data) {
        console.log(data);
    }
    function HideCollbse(data) {
        // alert(data);
        // $('.collapse').hide();
        // $('#order_'+data).show();
    }
    $('.nav-link').removeClass('active');
    $('#My_Orders').addClass('active');


    return (
        <>
            <div className="user-profile">

                <div className="container py-4 d-flex align-items-start">
                    <Sidebar />

                    <div className="col-10">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="My_Orders-tab"
                                role="tabpanel"
                                aria-labelledby="My_Orders"
                            >


                                <div className="row">
                                    <div className="col-12">
                                        <div className="my_order_input">
                                            <form>
                                                <div className="row">
                                                    <div>
                                                        <button type="button" className="btn btn-secondary mr-2" onClick={() => getOrders('orders')}> Order </button>
                                                        {/* <button type="button" className="btn btn-warning" onClick={() => getOrders('enquiry_orders')}> Enquiry Orders</button> */}
                                                    </div>
                                                    {
                                                        OrderData != null ?
                                                        <>
                                                        <h1 className="mt-4">Order Details</h1>
                                                    <div class="table-responsive">
                                                        {OrderData.map((item, index) => {
                                                            var StencilCartData = JSON.parse(item.StencilCartData);
                                                            var AssemblyCatData = JSON.parse(item.AssemblyCatData);
                                                            var LayoutCartData = JSON.parse(item.LayoutCartData);
                                                            return (
                                                                <div className="card p-3 mb-3">
                                                                    <div className="row" >
                                                                    <div className="col-md-4 mb-2">
                                                                            Order No: <b> {item.id} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            {/* Order Id: <b> {item.order_id} </b> */}
                                                                        </div>

                                                                        {/* <div className="col-md-4 mb-2">
                                                                            Total Amount:{item.total_amount}
                                                                        </div> */}
                                                                        <div className="col-md-4 mb-2">
                                                                            Order Date: <b>{dateFormat(item.created_at, "mmmm dS, yyyy")}</b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Services: <b> {item.AssemblyCatData != '[]' ? 'PCB Assembly ' : ''}
                                                                                {item.LayoutCartData != '[]' ? 'PCB Layout ' : ''}
                                                                                {item.StencilCartData != '[]' ? 'PCB Stencil ' : ''} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Payment Status: <b style={{ color: "red" }}> {item.payment_status}  </b>
                                                                            
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Order Status: <b style={{ color: "red" }}> {item.status} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2" onClick={HideCollbse(index)} >
                                                                            <a href="#" onClick={ViewOrder(item)} className="dd" data-bs-toggle="collapse" href={"#order_" + index} role="button" aria-expanded="false" aria-controls={"order_" + index}><button type="button" class="btn btn-secondary ml-1"><i class="fa fa-eye" aria-hidden="true"></i></button></a>
                                                                            <a href={"/Invoice/?order_id=" + item.order_id} className="dd"><button type="button" class="btn btn-warning"><i class="fa fa-print" aria-hidden="true"></i></button></a>
                                                                        </div>
                                                                    </div>
                                                                    <div class="collapse" id={"order_" + index}>
                                                                        <div class={item.AssemblyCatData != '[]' ? 'd-block' : 'd-none'}>
                                                                            <h5>Assembly Data :</h5>
                                                                            {AssemblyCatData.map((Assemblyitem, index3) => {
                                                                                return (
                                                                                    <div class="row">
                                                                                        <div class="col-md-4 mb-3">
                                                                                            pcb_name : <b>{Assemblyitem.pcb_name}</b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            no_of_layers : <b>
                                                                                                {Assemblyitem.no_of_layers}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            pcb_thickness : <b>
                                                                                                {Assemblyitem.pcb_thickness}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            pcb_dimensions : <b>
                                                                                                {Assemblyitem.pcb_dimensions}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            is_panel_required : <b>
                                                                                                {Assemblyitem.is_panel_required}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            panel_dimensions : <b>
                                                                                                {Assemblyitem.panel_dimensions}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            smd_components_pads : <b>
                                                                                                {Assemblyitem.smd_components_pads}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            throughhole_components_pads : <b>
                                                                                                {Assemblyitem.throughhole_components_pads}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            bga_components : <b>
                                                                                                {Assemblyitem.bga_components}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            board_to_be_assembled : <b>
                                                                                                {Assemblyitem.board_to_be_assembled}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            multifly_factor : <b>
                                                                                                {Assemblyitem.multifly_factor}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            gerber_data : <b>
                                                                                                <a href={APP_URL + 'image/' + Assemblyitem.gerber_data}>Click to View</a>
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            Bill of Materials : <b>
                                                                                                <a href={APP_URL + 'image/' + Assemblyitem.bill_of_materials}>Click to View</a>
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            price : <b>
                                                                                                ₹ {Assemblyitem.price}
                                                                                            </b>
                                                                                        </div>
                                                                                        <hr></hr>
                                                                                    </div>
                                                                                )
                                                                            })}
                                                                            <hr></hr>
                                                                        </div>
                                                                        <div class={item.LayoutCartData != '[]' ? 'd-block' : 'd-none'}>
                                                                            <h5>Layout Data :</h5>
                                                                            {LayoutCartData.map((Layoutitem, index2) => {
                                                                                return (
                                                                                    <div class="row">
                                                                                        <div class="col-md-4 mb-3">
                                                                                            pcb_name : <b>
                                                                                                {Layoutitem.pcb_name}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            layer : <b>
                                                                                                {Layoutitem.layer}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            components : <b>
                                                                                                {Layoutitem.components}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            lead_time : <b>
                                                                                                {Layoutitem.lead_time}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            Schematic : <b>
                                                                                                <a href={APP_URL + 'image/' + Layoutitem.schematic}>Click to View</a>
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            Bill of Materials : <b>
                                                                                                <a href={APP_URL + 'image/' + Layoutitem.bill_of_materials}>Click to View</a>
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            price : <b>
                                                                                                ₹ {Layoutitem.price}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            Price with Tax : <b>
                                                                                                ₹
                                                                                                {Layoutitem.total_price_with_tex}
                                                                                            </b>
                                                                                        </div>

                                                                                    </div>

                                                                                )
                                                                            })}
                                                                            <hr></hr>
                                                                        </div>
                                                                        <div class={item.StencilCartData != '[]' ? 'd-block' : 'd-none'}>
                                                                            <h5>Stencil Data :</h5>
                                                                            {/* {item.StencilCartData} */}
                                                                            {StencilCartData.map((Stencilitem, index2) => {
                                                                                return (
                                                                                    <div class="row">
                                                                                        <div class="col-md-4 mb-3">
                                                                                            pcb_name : <b>
                                                                                                {Stencilitem.pcb_name}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            stencil_variants : <b>
                                                                                                {Stencilitem.stencil_variants}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            stencil_sizes : <b>
                                                                                                {Stencilitem.stencil_sizes}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            lead_time : <b>
                                                                                                {Stencilitem.lead_time}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            schematic : <b>
                                                                                                {Stencilitem.schematic}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            price : <b>
                                                                                                ₹ {Stencilitem.price}
                                                                                            </b>
                                                                                        </div>
                                                                                        <div class="col-md-4 mb-3">
                                                                                            total_price_with_tex : <b>
                                                                                                ₹
                                                                                                {Stencilitem.total_price_with_tex}
                                                                                            </b>
                                                                                        </div>

                                                                                    </div>

                                                                                )
                                                                            })}
                                                                            <hr></hr>

                                                                        </div>
                                                                    </div>
                                                                    {/* <hr></hr> */}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    </>
                                                   : 
                                                   <>
                                                    <h1 className="mt-4">Enquiry to Order</h1>
                                                    <div class="table-responsive" id="component_sourcing">
                                                        <h4 className="" id="title">Component Sourcing Orders</h4>
                                                        <hr></hr>
                                                        {component_sourcingData.map((item, index) => {
                                                            return (
                                                                <div className="card p-3 mb-3">
                                                                    {/* data-bs-toggle="collapse" href={"#order_" + index} role="button" aria-expanded="false" aria-controls={"order_" + index} */}
                                                                    <div className="row" >
                                                                        <div className="col-md-4 mb-2">
                                                                            Enquiry No: <b> {item.enquiry_no} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            PCB Name: <b> {item.pcb_name} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Status: {item.status}
                                                                        </div>
                                                                        <div classN ame="col-md-4 mb-2">
                                                                            Enquiry Date: <b>{dateFormat(item.created_at, "mmmm dS, yyyy")}</b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            View File :
                                                                            <a href={APP_URL+'image/'+item.bill_of_material} target="_blank" className="badge bg-primary ml-2">View</a>
                                                                            <a href={"/TrackEnquery/?id="+item.id+"&type=component_sourcing"} className="badge bg-success ml-2">View and Track</a>
                                                                        </div>
                                                                    </div>
                                                                    {/* <hr></hr> */}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div class="table-responsive" id="fabrication">
                                                        <h4 className="" id="title">Fabrication Orders</h4>
                                                        <hr></hr>

                                                        {FabricationData.map((item, index) => {
                                                            return (
                                                                <div className="card p-3 mb-3">
                                                                    <div className="row" >
                                                                        <div className="col-md-4 mb-2">
                                                                            Enquiry No: <b> {item.enquiry_no} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Pcb Name:{item.pcb_name}
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Status: {item.status}
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Enquiry Date: <b>{dateFormat(item.created_at, "mmmm dS, yyyy")}</b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            View File :
                                                                            <a href={'?'+APP_URL+'image/'+item.material} target="_blank">Click here</a>
                                                                            {/* <a href={"/TrackEnquery/?id="+item.id+"&type=fabrication"} className="badge bg-success ml-2">View and Track</a> */}
                                                                            <a href={"/TrackEnquery/?id="+item.id+"&type=fabrication"} className="badge bg-success ml-2">View and Track</a>
                                                                        </div>
                                                                    </div>
                                                                    {/* <hr></hr> */}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>



                                                   </>
 }
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </div>
            </div>




            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </button> */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Orders;