import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import dateFormat from 'dateformat';
import Sidebar from './dashboardsidebar';



function UserProfile() {
    const navigate = useNavigate()

    const [profile, setProfile] = useState([])
    const [UserData, setUserData] = useState([])


    const [OrderData, setOrderData] = useState([])
    const token = localStorage.getItem("token")
    useEffect(() => {
        const token = localStorage.getItem("token")
        console.log(token, "token for cart")
        const url = APP_URL + 'userProfile'
        console.log(url);
        console.log(APP_URL);


        fetch(APP_URL + 'userProfile', {
            headers: {
                Authorization: `${token}`
            }
        }).then((res) => res.json()).then((data) => {

            // console.log(data,"value of cart")
            console.log(data, 'data')
            setProfile(data.message)
            setUserData(data.message[0])
            
            const lvlup = data.message.filter((item) => {
                localStorage.setItem('name', item.firstname)
                console.log(item.firstname);
            })
        })
        var myHeaders = new Headers();
        myHeaders.append("authorization", token);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(APP_URL + "my/orders", requestOptions)
            .then((response) => response.json())
            .then((orderResult) => {
                setOrderData(orderResult.OrderData);
            })
            .catch((error) => console.log("error", error));
    }, [])
    console.log("Order Data Get Successfully ::: ", OrderData);

    function Edit(id) {
        navigate("/Edit/" + id)
    }
    function Editp(id) {
        navigate("/Editpage/" + id)
    }
    function ViewOrder(data) {
        console.log(data);
    }
    return (
        <>
            <div className="user-profile">

                <div className="container py-4 d-flex align-items-start">
                    {/* <div className="row">
                        <div className="col-2">
                            <div className="overview_box py-2">
                                <h3>Overview</h3>
                            </div>
                            <hr className="border_hr" />
                            <div
                                className="nav flex-column nav-pills overview_box me-3"
                                id="v-pills-tab"
                                role="tablist"
                                aria-orientation="vertical"
                            >
                                <h3 className="mb-0">ORDERS</h3>
                                <button
                                    className="nav-link"
                                    id="My_Orders"
                                    data-bs-toggle="pill"
                                    data-bs-target="#My_Orders-tab"
                                    type="button"
                                    role="tab"
                                    aria-controls="My_Orders"
                                    aria-selected="true"
                                >
                                    My Orders
                                </button>
                                <button
                                    className="nav-link"
                                    id="shipped-Orders-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#shipped-Orders"
                                    type="button"
                                    role="tab"
                                    aria-controls="shipped-Orders"
                                    aria-selected="false"
                                >
                                    shipped Orders
                                </button>
                                <button
                                    className="nav-link"
                                    id="Exceptions-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Exceptions"
                                    type="button"
                                    role="tab"
                                    aria-controls="Exceptions"
                                    aria-selected="false"
                                >
                                    Exceptions
                                </button>
                                <button
                                    className="nav-link"
                                    id="v-pills-settings-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-settings"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-settings"
                                    aria-selected="false"
                                >
                                    Excalation
                                </button>
                                <hr className="border_hr " />
                                <h3 className="mb-0">ACCOUNT</h3>
                                <button
                                    className="nav-link active"
                                    id="v-pills-home-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-home"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-home"
                                    aria-selected="true"
                                >
                                    User Profile
                                </button>
                                <button
                                    className="nav-link"
                                    id="v-pills-profile-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-profile"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-profile"
                                    aria-selected="false"
                                >
                                    Address Management
                                </button>
                                <button
                                    className="nav-link"
                                    id="v-pills-messages-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#v-pills-messages"
                                    type="button"
                                    role="tab"
                                    aria-controls="v-pills-messages"
                                    aria-selected="false"
                                >
                                    Email Management
                                </button>
                                <hr className="border_hr " />
                                <h3 className="mb-0">TRACKING</h3>
                                <button
                                    className="nav-link"
                                    id="Track-Inquiry-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Track-Inquiry"
                                    type="button"
                                    role="tab"
                                    aria-controls="Track-Inquiry"
                                    aria-selected="true"
                                >
                                    Track Inquiry
                                </button>
                                <button
                                    className="nav-link"
                                    id="Track-Quotation-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Track-Quotation"
                                    type="button"
                                    role="tab"
                                    aria-controls="Track-Quotation"
                                    aria-selected="false"
                                >
                                    Track Quotation
                                </button>
                                <button
                                    className="nav-link"
                                    id="Email-Management_sec-tab"
                                    data-bs-toggle="pill"
                                    data-bs-target="#Email-Management_sec"
                                    type="button"
                                    role="tab"
                                    aria-controls="Email-Management_sec"
                                    aria-selected="false"
                                >
                                    Email Management
                                </button>
                            </div>
                        </div>
                    </div> */}
                    <Sidebar />

                    <div className="col-10">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="v-pills-home"
                                role="tabpanel"
                                aria-labelledby="v-pills-home-tab"
                            >
                                <div className="">
                                    <div className="reg_form_main">
                                        <h3>User Profile</h3>
                                    </div>
                                    <div className="overview_box_main mt-3">
                                        {/* {profile.map((user) => {
                                            return ( */}
                                                <div className="overview_box">
                                                    <div className="d-flex" key={UserData.id}>
                                                        <h5>{UserData.firstname} {UserData.lastname}</h5>
                                                    </div>
                                                    <div>
                                                        <p className=""><b>Company Name :</b> {UserData.company_name} </p>
                                                        
                                                    </div>
                                                    <div>
                                                    <p className="mt-2"><b>Mobile No :</b> {UserData.mobileno}</p> 
                                                    </div>
                                                    <div>
                                                    <p className=" mt-2"><b>Email :</b> {UserData.email}</p> 
                                                    </div>
                                                    {/*                                                     
                                                    <p>{UserData.company_name}</p>
                                                    <p>{UserData.mobileno}</p>
                                                    <p>{UserData.email}</p>
                                                    <p></p> */}
                                                    {/* <button id="selectRegister" type="submit" className="button_1 ">
                                            <span>{UserData.id}</span>
                                        </button> */}
                                        

                                                    <button className="btn btn-success" onClick={() => Editp(UserData.id)}><i class="fa fa-pencil" aria-hidden="true"></i>
</button>
                                                </div>
                                            {/* )
                                        })} */}

                                    </div>
                                    <hr className="border_hr" />
                                    <div className="overview_box_main mt-3">
                                        <div className="overview_box py-4">
                                            <h3>Address Management</h3>
                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            {/* {profile.map((user) => {
                                                console.log(user);
                                                return ( */}

                                                    <div className="Address_management_box" key={UserData.id}>

                                                        <h5>{UserData.firstname}</h5>
                                                        <hr className="border_hr" />
                                                        <p>
                                                            <b>Address: </b> {UserData.address1}
                                                        </p>
                                                        <p>
                                                            <b>City: </b> {UserData.city}
                                                        </p>
                                                        <p>
                                                            <b>State: </b> {UserData.state}
                                                        </p>
                                                        <p>
                                                            <b>District: </b> {UserData.districk}
                                                        </p>
                                                        <p>
                                                            <b>Pincode: </b> {UserData.pincode}
                                                        </p>

                                                        <p>
                                                            <b>Country: </b> {UserData.country}
                                                        </p>

                                                        {/* <p>
                                                            <b>Address Type:</b> Delever
                                                        </p> */}


                                                        <hr className="border_hr" />
                                                        <div className="botton_d_e d-flex align-items-center">
                                                            <button
                                                                id="selectRegister"
                                                                type="submit"
                                                                className="button_1 bg_success mt-3 "

                                                            >
                                                                <a onClick={() => { Edit(UserData.id) }}> Edit</a>


                                                            </button>
                                                            <button
                                                                id="selectRegister"
                                                                type="submit"
                                                                className="button_1 bg_danger mt-3 ms-4"
                                                            >
                                                                <span>Delete</span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                {/* )
                                            })} */}

                                        </div>
                                        {/* <div className="col-md-6">
                                        {profile.map((user) => {
                                                return (

                                                    <div className="Address_management_box">
                                                        <h5>{UserData.firstname}</h5>
                                                        <hr className="border_hr" />
                                                        <p>
                                                            <b>Address:</b> {UserData.address2}
                                                        </p>
                                                        <p>
                                                            <b>Contact Nr:</b> {UserData.mobileno}
                                                        </p>
                                                        <p>
                                                            <b>GST:</b>{UserData.gst}
                                                        </p>
                                                        <p>
                                                            <b>Address Type:</b> Invoice
                                                        </p>
                                                        <hr className="border_hr" />
                                                        <div className="botton_d_e d-flex align-items-center">
                                                            <button
                                                                id="selectRegister"
                                                                type="submit"
                                                                className="button_1 bg_success mt-3 "
                                                            >
                                                                <span>Edit</span>
                                                            </button>
                                                            <button
                                                                id="selectRegister"
                                                                type="submit"
                                                                className="button_1 bg_danger mt-3 ms-4"
                                                            >
                                                                <span>Delete</span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                )
                                            })}

                                        </div> */}
                                    </div>
                                    <hr className="border_hr" />
                                    {/* <div className="Address_management_box mt-3">
                                        <form>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="form_group_1">
                                                        <label className="form__label">Industry:</label>
                                                        <select
                                                            name={909}
                                                            className="form_group_select_box select_custum"
                                                        >
                                                            <option>Others</option>
                                                            <option>Company</option>
                                                            <option>Government organization</option>
                                                            <option>Individual</option>
                                                            <option>Educational institution</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form_group_1">
                                                        <label className="form__label">
                                                            Source of information:
                                                        </label>
                                                        <select
                                                            name={909}
                                                            className="form_group_select_box select_custum"
                                                        >
                                                            <option>Search Engion</option>
                                                            <option>Company</option>
                                                            <option>Government organization</option>
                                                            <option>Individual</option>
                                                            <option>Educational institution</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="form_group_1">
                                                        <label className="form__label">Registrred As:</label>
                                                        <select
                                                            name={909}
                                                            className="form_group_select_box select_custum"
                                                        >
                                                            <option>Company</option>
                                                            <option>Government organization</option>
                                                            <option>Individual</option>
                                                            <option>Educational institution</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-4">
                                                    <div className="form_group_1 form_group_gst">
                                                        <span>
                                                            <label className="form__label">GST Exemption</label>
                                                            <p>NO</p>
                                                        </span>
                                                        <span>
                                                            <label className="form__label">Tax</label>
                                                            <p>18%</p>
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-4">
                                                    <div className="form_group_1">
                                                        <label className="form__label">Company Type:</label>
                                                        <select
                                                            name={909}
                                                            className="form_group_select_box select_custum"
                                                        >
                                                            <option>Standard</option>
                                                            <option>Government organization</option>
                                                            <option>Individual</option>
                                                            <option>Educational institution</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-md-4 mt-4">
                                                    <div className="form_group_1">
                                                        <label className="form__label">Company`s URL:</label>
                                                        <input
                                                            type="text"
                                                            name="CompURL"
                                                            placeholder="Enter Your Company URL"
                                                            className="select_custum form_group_select_box"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-4">
                                                    <div className="form_group_1">
                                                        <button
                                                            id="selectRegister"
                                                            type="submit"
                                                            className="button_1 float-end mt-3 bg_success"
                                                        >
                                                            <span>Save</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> */}
                                    {/* <hr className="border_hr" />
                                    <div className="loream_green">
                                        <div className="overview_box_main mt-3">
                                            <div className="overview_box mt-3">
                                                <h3>Email Management</h3>
                                            </div>
                                            <button
                                                id="selectRegister"
                                                type="submit"
                                                className="button_1 mt-3 "
                                            >
                                                <span>Add More</span>
                                            </button>
                                        </div>
                                        <p>
                                            dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                                            temident, sunt in culpa qui officia deserunt mollit anim id est
                                            laborum. sunt in culpa qui officia deserunt mollit anim id est
                                            laborum. sunt in culpa qui officia deserunt mollit anim id est
                                            laborum.
                                        </p>
                                        <div className="emergancy_box_main">
                                            <div className="emergancy_box">
                                                <div className="row align-items-center">
                                                    <div className="col-md-4">
                                                        <p>Deepak227@gmail.com</p>
                                                    </div>
                                                    <div className="col-md-4 ">
                                                        <div className="check_box d-flex align-items-center">
                                                            <input type="checkbox" name="check" />
                                                            <span>Sales Notification</span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="check_box d-flex align-items-center">
                                                            <input type="checkbox" name="check" />
                                                            <span>Engineering Notification</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <li className="text-danger m-0">
                                                we will use mobile nr. only in the case of emergancy
                                            </li>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="overview_box_main mt-3">
                                            <div className="overview_box mt-3">
                                                <h3>Credit</h3>
                                            </div>
                                        </div>
                                        <div className="rounded Credit_box">
                                            <div className="row mt-3">
                                                <div className="col-md-6">
                                                    <ul className="ul_before_border">
                                                        <li className="d-flex align-items-center ">
                                                            <span>Outstanding Invoices</span>
                                                            <span>0</span>
                                                        </li>
                                                        <li className="d-flex align-items-center ">
                                                            <span>Running Order</span>
                                                            <span>0.00</span>
                                                        </li>
                                                        <li className="d-flex align-items-center ">
                                                            <span>Total Debit Notes</span>
                                                            <span>0</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="col-md-6">
                                                    <ul>
                                                        <li className="d-flex align-items-center ">
                                                            <span>Total Credit Notes</span>
                                                            <span>0</span>
                                                        </li>
                                                        <li className="d-flex align-items-center ">
                                                            <span>Payment Term</span>
                                                            <span>Advanced Basic</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="v-pills-profile"
                                role="tabpanel"
                                aria-labelledby="v-pills-profile-tab"
                            >
                                Address Management
                            </div>
                            <div
                                className="tab-pane fade"
                                id="v-pills-messages"
                                role="tabpanel"
                                aria-labelledby="v-pills-messages-tab"
                            >
                                Email Management
                            </div>
                            <div
                                className="tab-pane fade show"
                                id="My_Orders-tab"
                                role="tabpanel"
                                aria-labelledby="My_Orders"
                            >
                                <div className="row">
                                    <div className="col-12">
                                        <div className="my_order_input">
                                            <form>
                                                <div className="row">
                                                    <h1 className="mt-4">Order Details</h1>
                                                    <hr></hr>
                                                    {/* <div className="col-md-6 mt-3">
                                                        <div className="form_group_1">
                                                            <input
                                                                type="text"
                                                                name="CompURL"
                                                                placeholder="Order Nr"
                                                                className="select_custum form_group_select_box"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group_1 mt-3">
                                                            <input
                                                                type="text"
                                                                name="CompURL"
                                                                placeholder="PCB Name"
                                                                className="select_custum form_group_select_box"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group_1 mt-3">
                                                            <input
                                                                type="text"
                                                                name="CompURL"
                                                                placeholder="Order Date"
                                                                className="select_custum form_group_select_box"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mt-3">
                                                        <div className="form_group_1">
                                                            <input
                                                                type="text"
                                                                name="CompURL"
                                                                placeholder="Dispatch date"
                                                                className="select_custum form_group_select_box"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form_group_1 mt-3">
                                                            <button
                                                                id="selectRegister"
                                                                type="submit"
                                                                className="button_1 "
                                                            >
                                                                <span>Search</span>
                                                            </button>
                                                        </div>
                                                    </div> */}
                                                   
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className="tab-pane fade"
                                id="shipped-Orders"
                                role="tabpanel"
                                aria-labelledby="shipped-Orders-tab"
                            >
                                <h1 className="text-danger mt-5 text-center">No Orders Shipped</h1>

                            </div>
                            <div
                                className="tab-pane fade"
                                id="Exceptions"
                                role="tabpanel"
                                aria-labelledby="Exceptions-tab"
                            >
                                Exceptions
                            </div>
                            <div
                                className="tab-pane fade"
                                id="v-pills-settings"
                                role="tabpanel"
                                aria-labelledby="v-pills-settings-tab"
                            >
                                Excalation
                            </div>
                            <div
                                className="tab-pane fade"
                                id="Email_Management"
                                role="tabpanel"
                                aria-labelledby="Email_Management-tab"
                            >
                                teading Email Management
                            </div>
                            <div
                                className="tab-pane fade"
                                id="Track-Inquiry"
                                role="tabpanel"
                                aria-labelledby="Track-Inquiry-tab"
                            >
                                teading Track Inquiry
                            </div>
                            <div
                                className="tab-pane fade"
                                id="Track-Quotation"
                                role="tabpanel"
                                aria-labelledby="Track-Quotation-tab"
                            >
                                teading Track-Quotation
                            </div>
                            <div
                                className="tab-pane fade"
                                id="Email-Management_sec"
                                role="tabpanel"
                                aria-labelledby="Email-Management_sec-tab"
                            >
                                teading Email-Management_sec
                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </div>
            </div>



            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </button> */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default UserProfile;