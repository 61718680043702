import { useParams } from 'react-router-dom';

import Componentsorcing from './Service/Componentsorcing';
import Pcbassembly from './Service/Pcbassembly';
import Pcblayout from './Service/Pcblayout';
import Pcbfabrication from './Service/Pcbfabrication';
import Pcbstencil from './Service/Pcbstencil';


function ServiceSingle() {
    const { slug } = useParams();

    if(slug=='PCB-Layout'){
        return <Pcblayout active="PCB-Layout"/>

    }else if(slug=='PCB-Fabrication-PCB-Assembly'){
    return  <Pcbassembly active='PCB-Fabrication-PCB-Assembly'/>

    }else if(slug=='PCB-Fabrication'){
        return <Pcbfabrication active='PCB-Fabrication'/>

    }else if(slug=='PCB-Stencil'){
        return <Pcbstencil active='PCB-Stencil'/>
        
    }else if(slug == 'Component-Sourcing'){
        return <Componentsorcing active='Component-Sourcing'/>

    }else{
        return <Pcblayout active='PCB-Layout'/>
    }
    
}

export default ServiceSingle;