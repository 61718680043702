import React, { useEffect, useState } from "react";
import MyContext from "./mycontext";

function myState(props) {
    const [show, setshow] = useState(false);

return (
    <MyContext.Provider
        value={{
           show,setshow
        }}
    >
        {props.children}
    </MyContext.Provider>
);
}

export default myState;
