import React, { useState } from "react";
import img2 from "../img/icon.svg";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

function DisclaimerPlicy() {
  const[disclaimer,setdiscalimer]=useState([])
  useEffect(() => {
    const apiUrl = APP_URL + "getsetting"; // Replace this with your API endpoint URL
    console.log(apiUrl);

    // Fetch data from the API using the fetch() function
    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => {
        setdiscalimer(data.message);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);
  function removeTags(str) {
    if ((str===null) || (str===''))
      return false;
    else
      str = str.toString();
      
  
    return str.replace( /(<([^>]+)>)/ig, '');
  }

  return (
    <div className="disclaimer  ">
      <section className="container-fluid bg_img-12">
        <div className="container">
          <div className="Buy_Enclosure text-white">
            <h1>Disclaimer</h1>
            <div className="Buy Enclosure_Icon d-flex align-items-center">
              <a href="/">
                <svg
                  height="18px"
                  width="18px"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M20 20.0001C20 20.5524 19.5523 21.0001 19 21.0001H5C4.44772 21.0001 4 20.5524 4 20.0001V11.0001L1 11.0001L11.3273 1.61162C11.7087 1.26488 12.2913 1.26488 12.6727 1.61162L23 11.0001L20 11.0001V20.0001ZM11 13.0001V19.0001H13V13.0001H11Z"
                    fill="rgba(247,247,247,1)"
                  ></path>
                </svg>
              </a>
              <span className="ms-2 mt-1 fw-bold"> : Disclaimer policy</span>
            </div>
          </div>
        </div>
      </section>
      <div class="container">
       
          {disclaimer.map((item) => {
            return <p style={{display:'inline'}}>{ReactHtmlParser(item.disclaimer)}</p>;
          })}
          <br />
        
      </div>
    </div>
  );
}

export default DisclaimerPlicy;
