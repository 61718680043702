import React, { useEffect } from 'react';
import $ from 'jquery';

const EasebuzzCheckoutButton = () => {
  useEffect(() => {
    // Replace 'your_key' with your actual key
    const key = 'Q0I795KMZN';
    const access_key = '114e574f3d63ca3a88f5f2c470869f926b147faa8a41c63f6381254ab28d26a9'; // access key received via Initiate Payment
    const token = localStorage.getItem("token");
    // Load Easebuzz checkout script
    const script = document.createElement('script');
    script.src = 'https://ebz-static.s3.ap-south-1.amazonaws.com/easecheckout/easebuzz-checkout.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Initialize EasebuzzCheckout after script is loaded
      const easebuzzCheckout = new window.EasebuzzCheckout(key, 'prod');
      const queryParams = new URLSearchParams(window.location.search)
      const order_id = queryParams.get("order_id");
      // alert(order_id);
      // Set up click event for the button
      document.getElementById('ebz-checkout-btn').onclick = function (e) {
        // alert('Please Wait.');
         var myHeaders = new Headers();
          myHeaders.append("authorization", token);
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
          };
          fetch(APP_URL + "MakeOrderPayment/"+order_id, requestOptions)
            .then((response) => response.json())
            .then((orderResult) => {
              console.log("Order Store in DB Result ::: ", JSON.stringify(orderResult));
              // setUserData(orderResult.message)
              console.log("Order message ::: ", JSON.stringify(orderResult.message));
              // alert(orderResult.trans_id)
              if(orderResult.response.status == 1){
                // alert("Order Stored Please Complete Payment.");
                // dashboard
                const options = {
                  access_key: orderResult.response.data,
                  onResponse: (response) => {
                    console.log(response);
                    // alert(response);
                    // OrderPaymentStatusUpdate
                    var urlencoded = new FormData();
                    urlencoded.append("trans_id", orderResult.trans_id);
                    urlencoded.append("status", 'Processed');
                    urlencoded.append("response", JSON.stringify(response));

                    var settings = {
                      "url": APP_URL2 + "OrderPaymentStatusUpdate",
                      "method": "POST",
                      // "timeout": 0,
                      "timeout": 15000,
                      "cache": false,
                      "processData": false,
                      "mimeType": "multipart/form-data",
                      "contentType": false,
                      "data": urlencoded,
                      "headers": {
                        "authorization": token, // Replace "Bearer" with your token type if needed
                      }
                    };
                    $.ajax(settings).done(function (response) {
                      console.log(response);
                      // alert('Successfully Status Updated.');  
                      // naviagte('/Invoice/?order_id='+order_id);
                      window.location = '/Invoice/?order_id='+order_id;
                    });

                  },
                  theme: '#123456', // color hex
                };
                easebuzzCheckout.initiatePayment(options);
              }else{
                alert("Successfully Order Stored But Payment Faild. Please Go to Orders and make Payment Again.");
              }
              // navigate("/dashboard");
            })
            .catch((error) => console.log("error", error));
      };
    };

    return () => {
      // Cleanup script on component unmount
      document.head.removeChild(script);
    };
  }, []);

  return (
    <button id="ebz-checkout-btn" class='badge bg-primary p-2'>Proceed to Pay</button>
  );
};

export default EasebuzzCheckoutButton;