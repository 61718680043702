import { Link, useNavigate } from "react-router-dom";
import { useState, useContext } from "react";
import { Icon } from 'react-icons-kit';
import { eyeOff } from 'react-icons-kit/feather/eyeOff';
import { eye } from 'react-icons-kit/feather/eye'
// import {LoginContext } from "../LoginContext"
function ForgetPass() {

    // const [password, setPassword] = useState("");
    const [type, setType] = useState('password');
    const [icon, setIcon] = useState(eyeOff);
    const naviagate = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setpassword] = useState("");
    const [Message, setMessage] = useState("");
    // const {setLoginName,setloginStatus}=useContext(LoginContext)
    const [loggedIn, setLoggedIn] = useState(false);



    var token = localStorage.getItem('token');
    if (token != null) {
        // alert('test');
        // history.push('/dashboard');
        window.location = "/dashboard";
        naviagate("/dashboard");
    }

    function handleform(e) {
        e.preventDefault();
        const loginData = { email };
        fetch(APP_URL + "forgetPass", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(loginData),
        })
            .then((res) => {
                return res.json();
            })
            .then((data) => {
                console.log(data);
                alert(JSON.stringify(data.message));
                window.location='/login';
            });
    }
    return (
        <>
            <section id="login">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <h2>
                                <span class="colorlog">DIAC</span>
                                <span class="colorlogo">LABS</span> Forget Password
                            </h2>
                            <h3> {Message} </h3>

                            <form
                                method="post"
                                className="form edit-profiles top-50"
                                onSubmit={(e) => {
                                    handleform(e);
                                }}
                            >
                                <div className="from-group">
                                    <i className="fa fa-user" />
                                    <input
                                        className="form-control"
                                        type="text"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                        }}
                                        placeholder="Enter your Email"
                                    />

                                    <div className="from-group mt-2">
                                        <i class="fa fa-lock"></i>
                                    </div>
                                </div>
                               
                                <div className="row">
                                    {/* <div className="col">
                                        <div className="form-check" style={{ display: "flex" }}>
                                            <input
                                                type="checkbox"
                                                style={{ position: "relative", right: "1rem" }}
                                            ></input>
                                            <label
                                                className="form-check-label"
                                                style={{
                                                    display: "inline",
                                                    position: "relative",
                                                    left: "-0.7rem",
                                                    top: "0.7rem",
                                                }}
                                            >
                                                Remember me
                                            </label>
                                        </div>
                                    </div> */}
                                    <div
                                        className="col"
                                        style={{
                                            position: "relative",
                                            left: "2rem",
                                            marginTop: "12px",
                                        }}
                                    >
                                        <Link to="/login">Login Now ?</Link>
                                    </div>
                                </div>

                                <div>
                                    <div className="row">
                                        <div className="w-100 text-center">
                                            <button
                                                className="ms-auto mt-2 button--promo"
                                                type="submit"
                                                style={{
                                                    backgroundColor: '#717273'
                                                }}
                                            >
                                               Send Password Reset Link
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <p style={{ margin: 'auto', marginLeft: '20px', marginTop: '10px' }}>
                                I dont have an account:{" "}
                                <Link to="/registration">Create Account</Link>
                            </p>
                        </div>
                        {/* <div className="col-md-4"></div> */}
                    </div>
                </div>
            </section>
        </>
    );
}

export default ForgetPass;
