import { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import dateFormat from 'dateformat';
import Sidebar from '../dashboardsidebar';



function Enquiries() {
    const navigate = useNavigate()

    const [component_sourcingData, setcomponent_sourcingData] = useState([])
    const [fabricationData, setfabricationData] = useState([])
    const token = localStorage.getItem("token")
    useEffect(() => {
        const token = localStorage.getItem("token")
        console.log(token, "token for cart")
        const url = APP_URL + 'userProfile'
        console.log(url);
        console.log(APP_URL);

        var myHeaders = new Headers();
        myHeaders.append("authorization", token);
        var requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow",
        };
        fetch(APP_URL + "enquiries", requestOptions)
            .then((response) => response.json())
            .then((orderResult) => {
                setcomponent_sourcingData(orderResult.component_sourcingData);
                setfabricationData(orderResult.fabricationData);
            })
            .catch((error) => console.log("error", error));
    }, [])
    console.log("enquiries Data Get Successfully ::: ", component_sourcingData);

    function Edit(id) {
        navigate("/Edit/" + id)
    }
    function Editp(id) {
        navigate("/Editpage/" + id)
    }
    function ViewOrder(data) {
        console.log(data);
    }

    function myFunction(data) {
        if (data == "component_sourcing") {
            $('#component_sourcing').show();
            $('#fabrication').hide();
        }
        if (data == "fabrication") {
            $('#fabrication').show();
            $('#component_sourcing').hide();
        }
    }

    $('.nav-link').removeClass('active');
    $('#Enquiry-tab').addClass('active');

    return (
        <>
            <div className="user-profile">
                <div className="container py-4 d-flex align-items-start">
                    <Sidebar />
                    <div className="col-10">
                        <div className="tab-content" id="v-pills-tabContent">
                            <div
                                className="tab-pane fade show active"
                                id="My_Orders-tab"
                                role="tabpanel"
                                aria-labelledby="My_Orders"
                            >
                                
                                <div className="row">
                                    <div className="col-12">
                                        <div className="my_order_input">
                                            <form>
                                                <div className="row">
                                                    <div className="col-md-6 row">
                                                        <button type="button" className="mr-2 p-2 btn btn-primary" onClick={() => myFunction('component_sourcing')} style={{width: "fit-content"}}>Component Sourcing</button>
                                                        <button type="button" className="mr-2 p-2 btn btn-primary" onClick={() => myFunction('fabrication')} style={{width: "fit-content"}}>Fabrication </button>
                                                    </div>
                                                       
                                                    <div class="table-responsive" id="component_sourcing">
                                                        <h1 className="" id="title">Component Sourcing Enquiries</h1>
                                                        <hr></hr>
                                                        {component_sourcingData.map((item, index) => {
                                                            return (
                                                                <div className="card p-3 mb-3">
                                                                    {/* data-bs-toggle="collapse" href={"#order_" + index} role="button" aria-expanded="false" aria-controls={"order_" + index} */}
                                                                    <div className="row" >
                                                                        <div className="col-md-4 mb-2">
                                                                            Enquiry No: <b> EC_{item.id} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            PCB Name: <b> {item.pcb_name} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Status: {item.status}
                                                                        </div>
                                                                        <div classN ame="col-md-4 mb-2">
                                                                            Enquiry Date: <b>{dateFormat(item.created_at, "mmmm dS, yyyy")}</b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            View File :
                                                                            <a href={APP_URL+'image/'+item.bill_of_material} target="_blank" className="badge bg-primary ml-2">View</a>
                                                                            <a href={"/TrackEnquery/?id="+item.id+"&type=component_sourcing"} className="badge bg-success ml-2">View and Track</a>
                                                                        </div>
                                                                    </div>
                                                                    {/* <hr></hr> */}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>

                                                    <div class="table-responsive" id="fabrication">
                                                        <h1 className="" id="title">Fabrication Enquiries</h1>
                                                        <hr></hr>

                                                        {fabricationData.map((item, index) => {
                                                            return (
                                                                <div className="card p-3 mb-3">
                                                                    <div className="row" >
                                                                        <div className="col-md-4 mb-2">
                                                                            Enquiry No: <b> EF_{item.id} </b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Pcb Name:{item.pcb_name}
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Status: {item.status}
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            Enquiry Date: <b>{dateFormat(item.created_at, "mmmm dS, yyyy")}</b>
                                                                        </div>
                                                                        <div className="col-md-4 mb-2">
                                                                            View File :
                                                                            <a href={'?'+APP_URL+'image/'+item.material} target="_blank">Click here</a>
                                                                            {/* <a href={"/TrackEnquery/?id="+item.id+"&type=fabrication"} className="badge bg-success ml-2">View and Track</a> */}
                                                                            <a href={"/TrackEnquery/?id="+item.id+"&type=fabrication"} className="badge bg-success ml-2">View and Track</a>

                                                                        </div>
                                                                    </div>
                                                                    {/* <hr></hr> */}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">

                    </div>
                </div>
            </div>



            {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                Launch static backdrop modal
            </button> */}

            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            ...
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="button" class="btn btn-primary">Understood</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default Enquiries;